<template>
  <div class="hero d-flex justify-center align-center">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <v-card class="mx-auto elevation-5" rounded="lg">
            <div class="position-relative">
              <v-img
                src="@/assets/img/imagen1.jpg"
                height="200"
                class="background-image"
              >
                <div class="overlay"></div>
              </v-img>
            </div>
            
            <v-card-text class="pt-6">
              <div class="d-flex justify-center mb-6">
                <v-switch
                  v-model="entrada"
                  inset
                  color="primary"
                  :label="`${entrada ? 'ENTRADA' : 'SALIDA'}`"
                  class="ma-0"
                >
                </v-switch>
              </div>

              <v-text-field
                class="mb-2"
                ref="dniInput"
                v-model="valorCodigo"
                v-on:keyup.enter="actualizarLista()"
                autofocus
                :type="mostrarCodigo ? 'text' : 'password'"
                label="Escanear DNI"
                color="primary"
                outlined
                dense
                :append-icon="mostrarCodigo ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="mostrarCodigo = !mostrarCodigo"
              ></v-text-field>

              <v-btn 
                block 
                @click="handleForm()" 
                color="primary" 
                class="mt-4"
                rounded
              >
                Registro Manual
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Diálogo de respuesta -->
    <v-dialog v-model="dialog" width="auto" @click:outside="cerrarDialogo">
      <v-card class="response-dialog">
        <v-img
          src="@/assets/img/imagen2.jpg"
          width="600"
          aspect-ratio="1.2"
          contain
        ></v-img>
        <div class="message-footer" :class="{ 'error-message': respuesta.error }">
          <div class="text-h6 font-weight-bold">{{ respuesta.mensaje }}</div>
          <div v-if="!respuesta.error" class="text-subtitle-1 mt-1">{{ nombre }}</div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Componente de carga manual -->
    <CargaManual
      :handleDialog="dialogForm"
      :entrada="entrada"
      @cerrarForm="handleForm"
    />
  </div>
</template>

<script>
import axios from "axios";
import CargaManual from "../components/cargaManual.vue";

export default {
  name: "Home",
  components: {
    CargaManual,
  },
  data() {
    return {
      entrada: true,
      dialogForm: false,
      dialog: false,
      mostrarCodigo: false,
      valorCodigo: "",
      respuesta: {
        error: false,
        mensaje: "",
      },
      nombre: "",
      dialogTimer: null,
    };
  },
  mounted() {
    this.focusDniInput();
  },
  watch: {
    entrada() {
      this.$nextTick(() => {
        this.$refs.dniInput.$el.querySelector("input").focus();
      });
    },
  },
  methods: {
    focusDniInput() {
      this.$nextTick(() => {
        this.$refs.dniInput.$el.querySelector('input').focus();
      });
    },
    cerrarDialogo() {
      this.dialog = false;
      this.valorCodigo = ""; // Limpiar el input
      this.focusDniInput(); // Devolver el focus al input
      if (this.dialogTimer) {
        clearTimeout(this.dialogTimer);
        this.dialogTimer = null;
      }
    },
    handleForm(item) {
      this.dialogForm = item !== false;
    },
    mostrarDialogoConTimer() {
      this.dialog = true;
      if (this.dialogTimer) {
        clearTimeout(this.dialogTimer);
      }
      this.dialogTimer = setTimeout(() => {
        this.cerrarDialogo();
      }, 3000);
    },
    async actualizarLista() {
      if (!this.valorCodigo) return;
      
      const codigoLeido = this.valorCodigo;
      this.valorCodigo = ""; // Limpiar el input inmediatamente después de leerlo

      let datosPersonales = {
        nombre: "",
        dni: "",
      };

      try {
        const datos = codigoLeido.split('"');
        
        if (codigoLeido.startsWith("0") && datos.length >= 8) {
          datosPersonales.dni = datos[4];
          datosPersonales.nombre = datos[1] + " " + datos[2];
        } else if (datos.length >= 6) {
          datosPersonales.dni = datos[1].trim();
          datosPersonales.nombre = datos[4] + " " + datos[5];
        } else {
          throw new Error("Formato de DNI no válido");
        }

        if (!this.validarDatos(datosPersonales)) {
          throw new Error("Datos inválidos");
        }

        const response = await axios.post(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/api/acreditar`,
          {
            ...datosPersonales,
            entrada: this.entrada,
          }
        );

        this.respuesta = response.data;
        this.nombre = datosPersonales.nombre;
        this.mostrarDialogoConTimer();
      } catch (error) {
        console.error("Error:", error);
        this.respuesta = {
          error: true,
          mensaje: error.response?.data?.mensaje || "Error al procesar el DNI",
        };
        this.mostrarDialogoConTimer();
      }
    },
    validarDatos(datos) {
      const dniValido = /^\d{7,8}$/.test(datos.dni);
      const nombreValido = 
        datos.nombre &&
        datos.nombre.trim().length > 0 &&
        /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(datos.nombre);
      return dniValido && nombreValido;
    },
  },
  beforeDestroy() {
    if (this.dialogTimer) {
      clearTimeout(this.dialogTimer);
    }
  },
};
</script>

<style scoped>
.hero {
  min-height: 100vh;
  background: linear-gradient(45deg, #0A1A2A, #1B3B5D);
}

.position-relative {
  position: relative;
}

.background-image {
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.response-dialog {
  border-radius: 20px;
  overflow: hidden;
  margin: 0;
  background: #0A1A2A !important;
}

.response-dialog .v-img {
  display: block;
}

.message-footer {
  padding: 16px;
  background: rgba(255, 255, 255, 0.95);
  text-align: center;
  border-radius: 0 0 20px 20px;
}

.error-message {
  background: rgba(255, 235, 235, 0.95);
}
</style>
