<template>
  <v-dialog v-model="handleDialog" persistent max-width="500">
    <v-card>
      <div class="position-relative">
        <v-img
          src="@/assets/img/imagen1.jpg"
          height="200"
          class="background-image"
        >
          <div class="overlay"></div>
        </v-img>
      </div>

      <v-card-text class="pt-6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-center mb-6">
                <v-switch
                  v-model="entrada"
                  inset
                  color="primary"
                  :label="`${entrada ? 'ENTRADA' : 'SALIDA'}`"
                  class="ma-0"
                >
                </v-switch>
              </div>

              <v-text-field
                v-model="nombre"
                label="Nombre completo"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="dni"
                label="DNI"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="motivo"
                label="Motivo"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="pb-6 px-6">
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="$emit('cerrarForm', false)"
          rounded
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click="envioForm"
          :disabled="!nombre || !dni || !motivo"
          rounded
        >
          Registrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "cargaManual",
  props: {
    handleDialog: {
      type: Boolean,
      default: false,
    },
    entrada: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      nombre: "",
      dni: "",
      motivo: "",
    };
  },
  methods: {
    cerrarDialog() {
      this.$emit("cerrarForm", false);
    },
    async envioForm() {
      if (!this.nombre || !this.dni || !this.motivo) {
        alert("Por favor complete todos los campos");
        return;
      }

      let datosPersonales = {
        nombre: this.nombre,
        dni: this.dni,
        motivo: this.motivo,
        entrada: this.entrada,
      };

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/api/acreditar`,
          datosPersonales
        );
        
        console.log(response.data);
        this.nombre = "";
        this.dni = "";
        this.motivo = "";
        this.$emit("cerrarForm", false);
      } catch (error) {
        console.error(error);
        alert(error.response?.data?.mensaje || "Error al registrar el visitante");
      }
    },
  },
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.background-image {
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.v-text-field {
  margin-top: 0;
}
</style>
